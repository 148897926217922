import { gql } from 'urql'

const QuerySkillsList = gql<
  {
    skillsList: {
      collection: { id: number; name: string }[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query (
    $limit: Int!
    $page: Int!
    $search: String
    $excludeIds: [Int!]
    $profileId: Int
    $profileHeadline: String
  ) {
    skillsList(
      limit: $limit
      page: $page
      search: $search
      excludeIds: $excludeIds
      profileId: $profileId
      profileHeadline: $profileHeadline
    ) {
      collection {
        id
        name
        similar
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QuerySkillsList
